<template>
    <v-container class="pa-0" fluid>
        <v-row no-gutters>
            <v-col cols="12" class="mb-1">
                <span class="text-h5 primary-text--text pl-2">PDF 1.1</span>
            </v-col>
            <v-col cols="12">
                <v-text-field v-model.number="campaign">Campaign</v-text-field>
            </v-col>
            <v-col>
                <v-btn @click="pdfMake2">PDF</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import imageData from '@/imageDataUri'
import { apiGet } from '@/plugins/api'


export default {
    data () {
        return {
            campaign: 4
        }
    },
    mounted () {
        // console.log(images)
    },
    methods: {
        async pdfMake2 () {
            const res = await apiGet('/media_instructions', { 
                campaign: this.campaign,
                paging: 'none',
            })
            const content = [{ image: imageData.jcdlogo, width: 150 }]
            const images = {}
            res.data.results.forEach(x => {
                x.media_items.forEach(m => {
                    const file = m.thumbnail_url.replace('https://emmet-staging-thumbnails.s3.amazonaws.com/', '').split('?')[0].split('.')[0]
                    content.push({ 
                        image: file, 
                        fit: [100, 100]
                    })
                    images[file] = m.thumbnail_url
                })
            })
            // console.log(content)
            // console.log(images)            

            const docDefinition = {
                pageSize: 'A4',
                pageOrientation: 'landscape',
                pageMargins: 20,
                content: content,
                images: images
            }

            pdfMake.createPdf(docDefinition).open()
        },

        loadImage (src) {
            return new Promise((resolve, reject) => {
                const img = new Image()
                // img.crossOrigin = 'anonymous'
                img.onload = () => resolve(img)
                // img.onerror = () => reject()
                img.src = src
            })
        },
        getImageDataUri (img) {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext("2d")
            canvas.width = img.width
            canvas.height = img.height
            ctx.drawImage(img, 0, 0)
            const dataUri = canvas.toDataURL("image/png")
            // canvas.remove()
            // return dataUri
            // return canvas
            console.log(dataUri)
        },

        
        async imageThing () {
            const src1 = 'https://emmet-staging-thumbnails.s3.amazonaws.com/tesupload.mp4.jpg?AWSAccessKeyId=AKIAI2KMCEMW4OJEV3XQ&Signature=QiMRlWRSQVdEV8wjnVb2Fyx%2FvmA%3D&Expires=1631248963'

            // const input = document.getElementById('input')
            // const img = document.getElementById('img').parentNode.childNodes
            // console.log(img)

            // const bits = src1.replace('https://emmet-staging-thumbnails.s3.amazonaws.com/', '').split('?')
            // console.log(bits)



            // const response = await axios.get(src,  { responseType: 'arraybuffer' })
            // const response = await axios.get('http://localhost:8001/api/thumbnails')
            // const buffer = Buffer.from(response.data, "utf-8")


            // const fetch = require("node-fetch");

            // const getBuffer = async (url) => {
            // try {
            //     const response = await fetch(url);
            //     const arrayBuffer = await response.arrayBuffer();
            //     const buffer = Buffer.from(arrayBuffer);
            //     return buffer;
            // } catch (error) {
            //     return { error };
            // }
            // };

            // img.click()

            // input.click()

            // const img = new Image();
            // img.src = 'nebula.jpg';
            // img.decode()
            // .then(() => {
            // document.body.appendChild(img);
            // })
            // .catch((encodingError) => {
            // // Do something with the error.
            // })

            // input.value = img.src
            // console.log(input.value)
            // const src2 = 'https://emmet-staging-thumbnails.s3.amazonaws.com/dog_1080x1920.jpg.jpg?AWSAccessKeyId=AKIAI2KMCEMW4OJEV3XQ&Signature=p1VtQGz4sJOPuoWqT7tCu2cLXiA%3D&Expires=1631247091'
            // const img = await this.loadImage(src1)
            // const dataUri = this.getImageDataUri(img)
            // console.log(dataUri)
            // dataUri.toDataURL("image/png")

            // const res = await axios.get(src1)
            // console.log(res)

            // function toDataURL(url, callback) {
            //     var xhr = new XMLHttpRequest();
            //     xhr.onload = function() {
            //         var reader = new FileReader();
            //         reader.onloadend = function() {
            //             callback(reader.result);
            //         }
            //         reader.readAsDataURL(xhr.response);
            //     };
            //     xhr.open('GET', url);
            //     xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
            //     xhr.responseType = 'blob';
            //     xhr.send();
            // }
            // toDataURL(src1, function(dataUrl) {
            //     console.log('RESULT:', dataUrl)
            // })

            // const img = document.getElementById('img')
            // img.crossOrigin = "Anonymous"
            // console.log(img.crossOrigin)
            // const canvas = document.createElement('canvas')
            // const ctx = canvas.getContext("2d")
            // canvas.width = img.width
            // canvas.height = img.height
            // ctx.drawImage(img, 0, 0)
            // canvas.toDataURL("image/jpg")
            // let downloadedImg
            
            // function startDownload() {
            //     let imageURL = 'https://emmet-staging-thumbnails.s3.amazonaws.com/tesupload.mp4.jpg?AWSAccessKeyId=AKIAI2KMCEMW4OJEV3XQ&Signature=QiMRlWRSQVdEV8wjnVb2Fyx%2FvmA%3D&Expires=1631248963'

            //     // downloadedImg = new Image
            //     downloadedImg = document.getElementById('img')
            //     console.log(downloadedImg)
            //     downloadedImg.crossOrigin = "Anonymous"

            //     downloadedImg.addEventListener("load", imageReceived, false)
            //     downloadedImg.src = imageURL
            // }

            // function imageReceived() {
            //     let canvas = document.createElement("canvas");
            //     let context = canvas.getContext("2d");

            //     canvas.width = downloadedImg.width;
            //     canvas.height = downloadedImg.height;

            //     context.drawImage(downloadedImg, 0, 0);
            //     // imageBox.appendChild(canvas);

            //     try {
            //         localStorage.setItem("saved-image-example", canvas.toDataURL("image/png"));
            //     }
            //     catch(err) {
            //         console.log("Error: " + err);
            //     }
            // }

            // startDownload()

            // function loadXHR(url) {
            //     return new Promise(function(resolve, reject) {
            //         try {
            //             var xhr = new XMLHttpRequest();
            //             xhr.open("GET", url);
            //             xhr.responseType = "blob";
            //             xhr.onerror = function() {reject("Network error.")};
            //             xhr.onload = function() {
            //                 if (xhr.status === 200) {resolve(xhr.response)}
            //                 else {reject("Loading error:" + xhr.statusText)}
            //             };
            //             xhr.send();
            //         }
            //         catch(err) {reject(err.message)}
            //     });
            // }
            // loadXHR(src1).then(function(blob) {
            //     console.log(blob)
            // });


        },

        pdfmake () {
            const img = new Image()
            const getUrl = function (img) {
                const canvas = document.createElement('canvas')
                const ctx = canvas.getContext("2d")
                canvas.width = img.width
                canvas.height = img.height
                ctx.drawImage(img, 0, 0)
                return canvas.toDataURL("image/jpg")
            }
            img.onload = function () {
                const dataUrl = getUrl(img)
                // console.log(dataUrl)
                const dd = {
                    pageSize: 'A4',
                    pageOrientation: 'landscape',
                    pageMargins: 20,
                    content: [
                        {
                            alignment: 'justify',
                            columns: [
                                {
                                    image: dataUrl,
                                    width: 100
                                },
                                {
                                    margin: [20, 10, 0, 0],
                                    svg: `
                                    <svg    
                                        width="22px"
                                        height="20px"
                                        viewBox="0 0 500 500"        
                                    >
                                        <polygon :style="'fill: #fff;'" points="216.5 327.8 216.5 250 283.9 211.1 283.9 288.9 216.5 327.8"/>
                                        <polygon :style="'fill: #fff;'" points="216.5 414.9 359.3 332.4 359.3 167.5 433 125 433 375 216.5 500 216.5 414.9"/>
                                        <polygon :style="'fill: #fff;'" points="216.5 414.9 216.5 500 0 375 0 125 73.7 166 73.7 332.5 216.5 414.9"/>
                                        <polygon :style="'fill: #fff;'" points="0 125 73.7 83 73.7 166 0 125"/>
                                        <polygon :style="'fill: #fff;'" points="216.5 327.8 149.1 288.9 149.1 211.1 216.5 250 216.5 327.8"/>
                                        <polygon :style="'fill: #fff;'" points="216.5 250 149.1 211.1 216.5 172.2 283.9 211.1 216.5 250"/>
                                        <polygon :style="'fill: #fff;'" points="359.3 167.5 147.6 40.3 216.5 0 433 125 359.3 167.5"/>
                                        <polygon :style="'fill: #fff;'" points="147.6 124.9 147.6 40.3 219.39 83.43 147.6 124.9"/>
                                    </svg>                                    
                                    `
                                },
                                { text: 'Digital Campaign Performance Report', fontSize: 14, alignment: 'right', margin: [0, 14, 0, 0] },
                            ]
                        },
                        {
                            style: { fontSize: 10 },
                            margin: [0, 10, 0, 0],
                            // style: 'tableExample',
                            table: {
                                headerRows: 0,
                                widths: ['auto', '*', 'auto', '*'],
                                body: [
                                    [{ text:'Campaign ID', bold: true }, '0000116446', { text:'Sales Week', bold: true }, 'N/A'],
                                    [{ text:'Campaign Name', bold: true }, 'TEQ IMATE BEDA LFD', { text:'Planned Display Minutes', bold: true }, '8,982'],
                                    [{ text:'Client', bold: true }, 'Tourism Queensland', { text:'Actual Display Minutes', bold: true }, '12,264'],
                                    [{ text:'Brand', bold: true }, 'Tourism Queensland', { text:'Actual Display', bold: true }, '137%'],
                                    [{ text:'Agency', bold: true }, 'Ikon Communications Pty Ltd (QLD)', { text:'Report Generated', bold: true }, '04/08/2021 9:44AM'],
                                ]
                            },
                            layout: {
                                hLineWidth: () => 0.5,
                                vLineWidth: () => 0,
                                hLineColor: () => '#aaa',
                                paddingTop: () => 4,
                                paddingBottom: () => 4,
                            }
                        },
                        { text: 'Campaign Summary', fontSize: 12, margin: [0, 15, 0, 0] },
                        {
                            style: { 
                                fontSize: 8
                            },
                            margin: [0, 5, 0, 0],
                            table: {
                                headerRows: 1,
                                body: [ 
                                    [
                                        { text: 'Panel ID', bold: true }, 
                                        { text: 'State', bold: true }, 
                                        { text: 'Name', bold: true }, 
                                        { text: 'Digital Display Seconds', bold: true }, 
                                        { text: 'Bookings From', bold: true }, 
                                        { text: 'Bookings To', bold: true }, 
                                        { text: 'Planned Plays', bold: true }, 
                                        { text: 'Planned Display Minutes', bold: true }, 
                                        { text: 'Actual Plays', bold: true }, 
                                        { text: 'Actual Display Minutes', bold: true }, 
                                        { text: 'Actual Delivery', bold: true }, 
                                        { text: 'Actual SOT %', bold: true }, 
                                        { text: 'Creative', bold: true }, 
                                        { text: 'Creative Start', bold: true }, 
                                        { text: 'Creative End', bold: true }, 
                                        { text: 'Creative Plays', bold: true }, 
                                        { text: 'Creative Display Minutes', bold: true }
                                    ],
                                    [ '03433.01.11', 'NSW', 'Anzac Pde Overpass', '10', '21/06/2021', '27/06/2021', '5,292', '882', '10,116', '1,686', '191%', '19%', '---', '21/06/2021', '27/06/2021', '5,046', '841'],
                                    [ '03433.01.11', 'NSW', 'Anzac Pde Overpass', '10', '21/06/2021', '27/06/2021', '5,292', '882', '10,116', '1,686', '191%', '19%', '---', '21/06/2021', '27/06/2021', '5,046', '841'],
                                    [ '03433.01.11', 'NSW', 'Anzac Pde Overpass', '10', '21/06/2021', '27/06/2021', '5,292', '882', '10,116', '1,686', '191%', '19%', '---', '21/06/2021', '27/06/2021', '5,046', '841'],
                                    [ '03433.01.11', 'NSW', 'Anzac Pde Overpass', '10', '21/06/2021', '27/06/2021', '5,292', '882', '10,116', '1,686', '191%', '19%', '---', '21/06/2021', '27/06/2021', '5,046', '841'],
                                    [ '03433.01.11', 'NSW', 'Anzac Pde Overpass', '10', '21/06/2021', '27/06/2021', '5,292', '882', '10,116', '1,686', '191%', '19%', '---', '21/06/2021', '27/06/2021', '5,046', '841'],
                                    [ '03433.01.11', 'NSW', 'Anzac Pde Overpass', '10', '21/06/2021', '27/06/2021', '5,292', '882', '10,116', '1,686', '191%', '19%', '---', '21/06/2021', '27/06/2021', '5,046', '841'],
                                ]
                            },
                            layout: {
                                hLineWidth: (i) => i === 0 ? 0 : i === 1 ? 1 : 0.5,
                                vLineWidth: () => 0,
                                hLineColor: () => '#aaa',
                                // paddingTop: () => 4,
                                // paddingBottom: () => 4,
                                bold: (i) => i === 2 ? true : false
                            }
                        },
                        { image: 'snow' }
                    ],
                    images: {
                        snow: 'https://emmet-staging-thumbnails.s3.amazonaws.com/tesupload.mp4.jpg?AWSAccessKeyId=AKIAI2KMCEMW4OJEV3XQ&Signature=QiMRlWRSQVdEV8wjnVb2Fyx%2FvmA%3D&Expires=1631248963',
                        aaa: 'jcdlogo'
                    }
                }
                pdfMake.createPdf(dd).open()
                // pdfMake.createPdf(dd).download('sample_report.pdf')
            }
            img.src = require('@/assets/jcd_logo.png')

            

            var docDefinition = { 
                pageSize: 'A4',
                pageOrientation: 'landscape',
                pageMargins: [ 40, 20, 40, 60 ],
                content: [
                    { image: 'https://cdn.pixabay.com/photo/2015/04/19/08/32/marguerite-729510__340.jpg', height: 150 },
                    { text: 'Digital Campaign Performance Report', fontSize: 15, margin: [50, 0] },
                    { text: 'sample', margin: [ 5, 2, 10, 20 ] },
                ],
                pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
                    // return currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0;
                    // console.log(currentNode)
                }

                // pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
                //     var pageInnerHeight = currentNode.startPosition.pageInnerHeight;
                //     var top = (currentNode.startPosition.top) ? currentNode.startPosition.top : 0;
                //     var footerHeight = 30;
                //     var nodeHeight = 0;
                //     if (followingNodesOnPage && followingNodesOnPage.length) {
                //         nodeHeight = followingNodesOnPage[0].startPosition.top - top;
                //     }

                //     if (currentNode.headlineLevel === 'footer') return false;

                //     return (currentNode.image && (top + nodeHeight + footerHeight > pageInnerHeight))
                //         || (currentNode.headlineLevel === 'longField' && (top + nodeHeight + footerHeight > pageInnerHeight))
                //         || currentNode.startPosition.verticalRatio >= 0.95;
                // }



            };
            // pdfMake.createPdf({}).download();
            // pdfMake.createPdf({}).open();
            // pdfMake.createPdf({}).open({}, window)
            // pdfMake.createPdf({}).print();
            // const x = pdfMake.createPdf(docDefinition)
            // console.log(x)
            // x.getBase64(x => x)
            // x.getBuffer(x => console.log(x))
            // x.open()
        },

        pdf () {
            console.log('pdf')
            // const doc = new jsPDF({
            //     orientation: 'landscape',
            //     unit: 'mm',
            //     format: 'a4'
            // });

            // const x = Array.from(Array(50).keys()).map(x => 'Heeeey')

            // console.log(x)
            

            // doc.text("Hello world!", 10, 10);
            // doc.save("a4.pdf");


            // var canvas = document.getElementById('canvas');
            // var context = canvas.getContext('2d');
            // // draw a blue cloud
            // context.beginPath();
            // context.moveTo(170, 80);
            // context.bezierCurveTo(130, 100, 130, 150, 230, 150);
            // context.bezierCurveTo(250, 180, 320, 180, 340, 150);
            // context.bezierCurveTo(420, 150, 420, 120, 390, 100);
            // context.bezierCurveTo(430, 40, 370, 30, 340, 50);
            // context.bezierCurveTo(320, 5, 250, 20, 250, 50);
            // context.bezierCurveTo(200, 5, 150, 20, 170, 80);
            // context.closePath();
            // context.lineWidth = 5;
            // context.fillStyle = '#8ED6FF';
            // context.fill();
            // context.strokeStyle = '#0000ff';
            // context.stroke();
            // // only jpeg is supported by jsPDF
            // var imgData = canvas.toDataURL("image/jpeg", 1.0);
            // var pdf = new jsPDF();
            // pdf.addImage(imgData, 'JPEG', 0, 0);
            // pdf.save("download.pdf");




            // Example 1: url to blob js
            // fetch('https://upload.wikimedia.org/wikipedia/commons/7/77/Delete_key1.jpg')
            // .then(res => res.blob()) // Gets the response and returns it as a blob
            // .then(blob => {
            //     // Here's where you get access to the blob
            //     // And you can use it for whatever you want
            //     // Like calling ref().put(blob)

            //     // Here, I use it to make an image appear on the page
            //     let objectURL = URL.createObjectURL(blob);
            //     let myImage = new Image();
            //     myImage.src = objectURL;
            //     document.getElementById('myImg').appendChild(myImage)
            // });
            // Example 2: javascript create blob or data/image from image url
            // function convertImgToBase64URL(url, callback, outputFormat){
            //     var img = new Image();
            //     img.crossOrigin = 'Anonymous';
            //     img.onload = function(){
            //         var canvas = document.createElement('CANVAS'),
            //         ctx = canvas.getContext('2d'), dataURL;
            //         canvas.height = img.height;
            //         canvas.width = img.width;
            //         ctx.drawImage(img, 0, 0);
            //         dataURL = canvas.toDataURL(outputFormat);
            //         callback(dataURL);
            //         canvas = null; 
            //     };
            //     img.src = url;
            // }
            // convertImgToBase64URL('http://upload.wikimedia.org/wikipedia/commons/4/4a/Logo_2013_Google.png', function(base64Img){
            // alert('it works');
            //     $('.output').find('img').attr('src', base64Img);  
            
            // });
            //function convertImgToBase64URL will convert the image into data url


        }
    }

}
</script>

<style>

</style>